import { useWebIndexBody } from 'hooks/body/useWebIndexBody';
import { NoticePresenter } from './presenter';
import { useWindowSize } from 'hooks/useWindowSize';

import { defaultTheme } from 'theme/theme';

const breakPoints = defaultTheme.breakpoints;

export function Notice() {
	const { data, isLoading } = useWebIndexBody();
	const [windowWidth] = useWindowSize();

	if (isLoading || !data || !data.notice || data.notice.length === 0)
		return <></>;

	return (
		<NoticePresenter
			notice={data.notice}
			isMobile={
				windowWidth <
				parseInt(defaultTheme.breakpoints.mobile.replace('px', ''))
			}
		/>
	);
}
