import Marquee from 'react-fast-marquee';
import { noticeWrapper, noticeItem } from './styles';
import { NoticePresenterType } from './types';

export function NoticePresenter({ notice, isMobile }: NoticePresenterType) {
	return (
		<div css={noticeWrapper}>
			<Marquee speed={isMobile ? 150 : 200}>
				<li css={noticeItem}>{notice.join(' ')}</li>
			</Marquee>
		</div>
	);
}
