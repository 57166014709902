import { Theme, css, keyframes } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const noticeWrapper = (them: Theme) => css`
	display: flex;
	background-color: #f0f0f0;
	overflow: hidden;
	padding: 15px 0;
	white-space: nowrap;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	${maxScreen('mobile')} {
		padding: 0.6rem 0;
	}
`;

export const noticeItem = (theme: Theme) => css`
	display: inline-block;
	font-size: 1.2rem;
	color: ${theme.users.colors.primary};
	margin-right: 12rem;
	font-weight: bold;
	${maxScreen('mobile')} {
		font-size: 1.2rem;
		margin-right: 9rem;
	}
`;
